import React, { useEffect, useState } from "react"
import { Transition } from "@headlessui/react"
import { useDrag, Handler } from "@use-gesture/react"
import { primaryInput } from "detect-it"
import { useMedia } from "../lib/useMedia"
import { RenderConditionally } from "../components/renderConditionally"
import { DeclarativeLoadingGatsbyImage } from "../components/declarativeLoadingGatsbyImage"

// simple modulo function because the built-in can't handle negative numbers
const mod = (n: number, m: number) => {
  return ((n % m) + m) % m
}

export const ConceptGallery = ({ slice, context }: any) => {
  const items = slice?.items?.filter(item => item?.image?.gatsbyImageData),
    {
      conceptGalleryState,
      setConceptGalleryState,
    } = context

  const handleClick = () => {
      const newActiveIndex = mod(conceptGalleryState.activeIndex + 1, items.length)
      setConceptGalleryState({
        activeIndex: newActiveIndex,
        activeConcept: items[newActiveIndex]?.concept?.document?.data?.title ?? ``,
      })
    },
    handleDrag: Handler<"drag"> = ({ active, movement: [mx], direction: [xDir], velocity: [vx], cancel, tap }) => {
      if (active && vx > 0.2) {
        const newActiveIndex = mod(conceptGalleryState.activeIndex + (xDir > 0 ? -1 : 1), items.length)
        setConceptGalleryState({
          activeIndex: newActiveIndex,
          activeConcept: items[newActiveIndex]?.concept?.document?.data?.title ?? ``,
        })
        cancel()
      }    
    },
    bind = useDrag(
      handleDrag, {
      axis: `x`,
      preventScroll: true,
      preventScrollAxis: `y`,
    })

  const [isSSR, setIsSSR] = useState(true),
    isMobile = useMedia([`(max-width: 639px)`], [true], false),
    isDesktop = useMedia([`(min-width: 640px)`], [true], false)

  useEffect(() => {
    setIsSSR(false)
  }, [])
  
  if(!items || items.length === 0) return null

  return (
    <>
      <div
        {...(primaryInput === `touch` ? bind() : {})}
        className="fixed inset-0 cursor"
        onClick={handleClick}
      >      
        {items.map((item, i) => {
          const image = item.image,
            mobileImage = image?.thumbnails?.mobile,
            desktopLoadEagerly = [
                conceptGalleryState.activeIndex,
                mod(conceptGalleryState.activeIndex + 1, items.length)
              ].indexOf(i) > -1,
            mobileLoadEagerly = [
                mod(conceptGalleryState.activeIndex - 1, items.length),
                conceptGalleryState.activeIndex,
                mod(conceptGalleryState.activeIndex + 1, items.length)
              ].indexOf(i) > -1

          return (
            <Transition
              show={i === conceptGalleryState.activeIndex}
              as="div"
              appear={true}
              className="absolute inset-0 pointer-events-none transition-opacity duration-1000"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              key={i}
              unmount={false}
            >
              <div className={`${mobileImage?.gatsbyImageData ? `hidden sm:block` : ``} h-full`}>
                <RenderConditionally condition={isSSR || !mobileImage?.gatsbyImageData || isDesktop}>
                  <DeclarativeLoadingGatsbyImage
                    alt={image.alt ?? ``}
                    className="absolute inset-0 w-full h-full"
                    image={image.gatsbyImageData}
                    loading={desktopLoadEagerly ? `eager` : `lazy`}
                    objectFit="cover"
                    sizes="100vw"
                  />
                </RenderConditionally>
              </div>
              {mobileImage?.gatsbyImageData && (
                <div className="sm:hidden h-full">
                  <RenderConditionally condition={isSSR || isMobile}>
                    <DeclarativeLoadingGatsbyImage
                      alt={mobileImage?.alt ?? ``}
                      className="absolute inset-0 w-full h-full"
                      image={mobileImage?.gatsbyImageData}
                      loading={mobileLoadEagerly ? `eager` : `lazy`}
                      objectFit="cover"
                      sizes="100vw"
                    />
                  </RenderConditionally>
                </div>
              )}
              <footer className="fixed pointer-events-none inset-x-0 bottom-0 z-20 flex justify-end items-end p-8 pb-12 sm:pb-8 text-white">
                <ul className="flex flex-row space-x-10 space-y-0 text-right sm:text-left text-xs sm:text-15px">
                  <li>
                    <span className="-mr-1 p-1 sm:-m-3 sm:p-3 uppercase">
                      {i + 1}
                    </span>
                  </li>
                  <li>
                    <span className="-mr-1 p-1 sm:-m-3 sm:p-3 uppercase">
                      {item?.concept?.document?.data?.title ?? ``}
                    </span>
                  </li>
                </ul>
              </footer>
            </Transition>
          )
        })}
      </div>
    </>
  )
}
