import { Dialog, Transition } from "@headlessui/react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicLink } from "@prismicio/react"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import React, { useState } from "react"

export const JournalList = ({ slice }: any) => {
  const journalQuery = graphql`
    query JournalEntries {
      allPrismicJournalEntry(sort: {fields: data___date, order: DESC}) {
        nodes {
          _previewable
          data {
            caption
            date(formatString: "MMMM Mo, YYYY")
            image {
              alt
              gatsbyImageData
            }
            link {
              ...LinkFragment
            }
          }
        }
      }
    }
  `
  const staticData = useStaticQuery<Queries.JournalEntriesQuery>(journalQuery),
    { data, isPreview } = useMergePrismicPreviewData(staticData),
    [isOpen, setIsOpen] = useState(false),
    [selectedIndex, setSelectedIndex] = useState(0),
    selectedNode = data.allPrismicJournalEntry.nodes[selectedIndex].data,
    selectedImageData = selectedNode.image?.gatsbyImageData,
    selectedRatio = (selectedImageData?.width as number) / (selectedImageData?.height as number)

  return (
    <div className="space-y-10 sm:space-y-16">
      <Transition.Root show={isOpen} as={React.Fragment}>
        <Dialog
          className="absolute top-0 right-0 z-10"
          data-theme="dark"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-brand bg-opacity-95 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-30 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 cursor">
              <Transition.Child
                as={React.Fragment}
                enter="ease duration-500 will-change-transform"
                enterFrom="opacity-0 scale-[.975]"
                enterTo="opacity-100 scale-100"
                leave="ease duration-300 will-change-transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-[.975]"
              >
                <Dialog.Panel className="relative transform overflow-hidden text-left transition-all max-w-[60vw] flex flex-col items-center">
                  <div className="max-h-[60vh] overflow-hidden">
                    <Dialog.Title className="sr-only">{`Image ${selectedIndex} detail`}</Dialog.Title>

                    <GatsbyImage
                      alt={selectedNode.image?.alt ?? ``}
                      image={selectedImageData}
                      objectFit="contain"
                      style={selectedRatio > 1 ? {} : {
                        /* If image is square or vertical, it’s width is equal to the lesser of:
                         * it’s intrinsic width
                         * it’s ratio times 60vh
                         * 60vw
                         */
                        width: `min(${selectedImageData?.width}px, ${selectedRatio} * 60vh, 60vw)`
                      }}
                      sizes={`(min-width: 640px) ${selectedRatio > 1 ? `60vw` : `min(${selectedImageData?.width}px, ${selectedRatio} * 60vh, 60vw)`}, calc(100vw - 40px)`}
                    />
                  </div>

                  <div className="w-full flex flex-shrink-0 items-start justify-between mt-5">
                    <div className="mr-auto space-y-0 text-xs text-sand">
                      {(selectedNode.date || selectedNode.link) && (
                        <p>
                          {selectedNode.date ?? ``}
                          {selectedNode.date && selectedNode.link ? ` • ` : ``}
                          {selectedNode.link && (
                            <PrismicLink
                              field={selectedNode.link}
                              className="-m-0.5 p-0.5 underline transition hocus:text-sand/50"
                            >
                              {selectedNode.link.url}
                            </PrismicLink>
                          )}
                        </p>
                      )}
                      {selectedNode.caption && (
                        <p>{selectedNode.caption}</p>
                      )}
                    </div>
                    <button
                      onClick={() => setIsOpen(false)}
                      className="relative text-sand hocus:text-sand/50 transition-colors duration-500"
                      style={{ transform: `scale(${24.6 / 26.73 * 100}%)` }}
                    >
                      <div className="pointer-events-none">
                        <span className="sr-only">Close</span>
                        <svg
                          aria-hidden="true"
                          width="26.73"
                          height="26.73"
                        >
                          <g fill="none" stroke="currentColor" strokeWidth="3">
                            <path d="M1.061 1.061 25.67 25.67" />
                            <path d="M25.67 1.061 1.061 25.67" />
                          </g>
                        </svg>
                      </div>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {data.allPrismicJournalEntry.nodes.map((entry, index) => {
        const imageData = entry.data.image?.gatsbyImageData
        if(!imageData) return null        
        const width = (imageData?.width as number) / (imageData?.height as number) * 450

        return (
          <div key={entry.data.caption}>
            <button
              className="pointer-events-none sm:pointer-events-auto group"
              style={{ width: `min(${width}px, 100%)` }}
              onClick={() => {
                setSelectedIndex(index)
                setIsOpen(true)
              }}
            >
              <GatsbyImage
                alt={entry.data.image?.alt ?? ""}
                image={entry.data.image?.gatsbyImageData}
                objectFit="contain"
                className="max-h-[450px] pointer-events-none"
                sizes={`(min-width: 640px) ${width}px, calc(100vw - 64px)`}
              />
            </button>
            <div className="mt-5 space-y-0 text-xs">
              {(entry.data.date || entry.data.link) && (
                <p>
                  {entry.data.date ?? ``}
                  {entry.data.date && entry.data.link ? ` • ` : ``}
                  {entry.data.link && (
                    <PrismicLink
                      field={entry.data.link}
                      className="-m-0.5 p-0.5 underline transition hocus:text-brand/50"
                    >
                      {entry.data.link.url}
                    </PrismicLink>
                  )}
                </p>
              )}
              {entry.data.caption && (
                <p>{entry.data.caption}</p>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
