import React from "react"
import { usePrismicSettings } from "../lib/usePrismicSettings"

export const Contact = () => {
  const { data: { prismicSettings }, isPreview } = usePrismicSettings(),
    data = prismicSettings?.data,
    addressLineOne = `${data?.street_address ? `${data?.street_address}` : ``}`,
    addressLineTwo = `${data?.address_locality ? `${data?.address_locality}, ` : ``}${data?.address_region_full ? `${data?.address_region_full} ` : ``}${data?.postal_code ? `${data?.postal_code}` : ``}`,
    phone = data?.phone,
    email = data?.email,
    press = data?.press

  return (
    <div className="flex h-full flex-col space-y-5 sm:space-y-9 text-15px sm:text-xl lg:text-3xl">
      {/* Address */}
      {(addressLineOne || addressLineTwo) && (
        <span className="flex items-baseline">
          <span className="w-14 text-xs lg:text-15px">Address</span>{" "}
          <a
            href={`https://www.google.com/maps/search/${addressLineOne} ${addressLineTwo}`.replace(/\s+/g, `+`)}
            className="-my-1 ml-9 mr-1 p-1 transition hocus:text-brand/50"
          >
            {addressLineOne}
            {addressLineOne !== `` && (
              <br />
            )}
            {addressLineTwo}
          </a>
        </span>
      )}

      {/* Phone */}
      {phone && (
        <span className="flex items-baseline">
          <span className="w-14 text-xs lg:text-15px">Phone</span>{" "}
          <a
            href={`tel:${phone}`}
            className="-my-1 ml-9 mr-1 p-1 transition hocus:text-brand/50"
          >
            {phone}
          </a>
        </span>
      )}

      {/* E-Mail */}
      {email && (
        <span className="flex items-baseline">
          <span className="w-14 text-xs lg:text-15px">Email</span>{" "}
          <a
            href={`mailto:${email}`}
            className="-my-1 ml-9 mr-1 p-1 transition hocus:text-brand/50"
          >
            {email}
          </a>
        </span>
      )}

      {/* Press */}
      {press && (
        <span className="flex items-baseline">
          <span className="w-14 text-xs lg:text-15px">Press</span>{" "}
          <a
            href={`mailto:${press}`}
            className="-my-1 ml-9 mr-1 p-1 transition hocus:text-brand/50"
          >
            {press}
          </a>
        </span>
      )}

      {/* Subscribe */}
      {/* <span className="flex items-baseline pt-40">
        <span className="w-14 text-xs lg:text-15px">Subscribe</span>
      </span> */}
    </div>
  )
}
