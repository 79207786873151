import { SliceZone } from "@prismicio/react"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview, useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import React, { ReactElement } from "react"
import { components } from "../slices"

const PageTemplate = (
  props: PageProps<Queries.PageByUidQuery>
): ReactElement => {
  const { data: staticData, ...rest } = props,
    { data, isPreview } = useMergePrismicPreviewData(staticData),
    pageContent = data.prismicPage,
    page = pageContent?.data,
    hasConceptGallery = page?.body?.find(slice => slice?.slice_type === `concept_gallery`)

  return (
    <div className="min-h-full">
      {/* Don’t render page title if the page contains a concept gallery */}
      {!hasConceptGallery && (
        <header className="hidden sm:block fixed z-20 left-[20vw] top-8 -mt-1.5">
          <h1 className="text-22px">{page?.title}</h1>
        </header>
      )}
      <main className="mt-48 sm:mt-[20vw] mb-[145px] sm:mb-[129px]">
        {/* Don’t render page title if the page contains a concept gallery */}
        {!hasConceptGallery && (
          <h1 className="sm:hidden text-15px mb-24">{page?.title}</h1>
        )}
        <SliceZone slices={page?.body?.filter(s => s?.slice_type)} components={components} context={rest} />
      </main>
    </div>
  )
}

const PageTemplateWithPreview = withPrismicPreview(PageTemplate)

export default PageTemplateWithPreview

export const PageQuery = graphql`
  query PageByUid($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      data {
        body {
          ... on PrismicPageDataBodyConceptGallery {
            id
            slice_type
            items {
							concept {
								document {
									... on PrismicConcept {
                    data {
                      title
                    }
                  }
                }
              }
              image {
                alt
								gatsbyImageData
                thumbnails {
									mobile {
                    alt
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyContact {
            id
            slice_type
          }
          ... on PrismicPageDataBodyJournalList {
            id
            slice_type
          }
          ... on PrismicPageDataBodyRichText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
        }
        title
      }
      type
      uid
    }
  }
`
