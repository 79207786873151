import { PrismicLink, PrismicRichText } from "@prismicio/react"
import React from "react"

export const RichText = ({ slice }: any) => {
  return (
    <PrismicRichText
      components={{
        paragraph: ({ children, key }) => (
          <p key={key} className="mt-5 text-15px sm:text-3xl">
            {children}
          </p>
        ),
        hyperlink: ({ node, children, key }) => (
          <PrismicLink
            key={key}
            field={node.data}
            className="-m-0.5 p-0.5 underline transition focus:outline-none focus-visible:ring focus-visible:ring-brand hocus:text-brand/50"
          >
            {children}
          </PrismicLink>
        ),
      }}
      field={slice?.primary?.text?.richText}
    />
  )
}
