import { ConceptGallery } from "./ConceptGallery"
import { Contact } from "./Contact"
import { JournalList } from "./JournalList"
import { RichText } from "./RichText"

export const components = {
  concept_gallery: ConceptGallery,
  contact: Contact,
  journal_list: JournalList,
  meta: () => null,
  rich_text: RichText,
}